
window.initTableFilters = function(table) {

     var $all     = $('#box-filtri').find('input, select');
     var $inputs  = $('#box-filtri').find('input');
     var $selects = $('#box-filtri').find('select');

     // Ripristina stato precedente
     var state = table.api().state.loaded();
     var columns = table.api().columns().settings()[0].aoColumns;

     if( /*window.location.hash == '#back' &&*/ !!state ) {
       var has_filters = false;
       var colname = null;
       var $input = null;

      $.each(state.columns, function(i, v) {
        if( v.search.search != '' ) {
          has_filters = true;

          colname = columns[i].data;
          $input = $($all.filter('[data-column='+colname+']'));

          $input.val(v.search.search);
          if( $input.hasClass('select2ajax') ) {

            var $option = $('<option selected>Caricamento...</option>').val(v.search.search);
            $input.append($option).trigger('change.select2');

            if( $input.data('initvalurl') ) {

              $.get(
                $input.data('initvalurl'),
                { id: v.search.search },
                function(d){
                  $option = $('<option selected>'+d.label+'</option>').val(v.search.search);
                  $input.append($option).trigger('change.select2');
                },
                'json'
              ).error(function(d){
                $option = $('<option selected>[ID:'+v.search.search+']</option>').val(v.search.search);
                $input.append($option).trigger('change.select2');
              });

            }
            else {
              $option = $('<option selected>[ID:'+v.search.search+']</option>').val(v.search.search);
              $input.append($option).trigger('change.select2');
            }

            // $input.select2('reload');
          }
        }
      });

      if( has_filters )
        $('#box-filtri').addClass('in');
     }

     // Search
     var deb_input_search = _.debounce(function(e) {
       var $input = $(e.target);
       var column_name = $input.data('column');
       var column = table.api().column(column_name+':name');

       column.search($input.val()).draw();
     }, 500);

     var deb_select_search = _.debounce(function(e) {
       var $select = $(e.target);
       var column_name = $select.data('column');
       var column = table.api().column(column_name+':name');

       // console.log($select.hasClass('select2ajax'));
       // console.log($select.val());
       // console.log(column_name);
       // console.log(column);

      /*if( $select.hasClass('select2ajax') && $select.val() != '' )
        column.search($select.val()).draw();
      else */
      if( $select.find('option:selected').length > 0 )
        column.search($select.find('option:selected').val()).draw();
      else
       column.search('').draw();

     }, 500);

     // Events
     $inputs.on('keyup change', deb_input_search);
     $selects.on('change', deb_select_search);

   };
