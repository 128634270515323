(function() {

  window.inputNumber = function(el) {

    var min = el.attr('min') || false;
    var max = el.attr('max') || false;

    var els = {};

    els.dec = el.prev();
    els.inc = el.next();

    el.each(function(i, v) {
      init($(v));
    });    

    function init(el) {

      function decrement() {
        var value = el[0].value;
        value--;
        if(!min || value >= min) {
          el[0].value = value;
        }
        // aggiornaPrezzi();
        el.trigger('updated')
      }

      function increment() {
        var value = el[0].value;
        value++;
        if(!max || value <= max) {
          el[0].value = value++;
        }
        // aggiornaPrezzi();
        el.trigger('updated')
      }

      els.dec.on('click', decrement);
      els.inc.on('click', increment);

    }
  }
})();
